import React from 'react'

import AccessControl from './src/components/access/AccessControl'
import { getCookieByName } from './src/utils/cookies'
import { ENV } from './src/constants/env'

export const wrapPageElement = () => {
  const isWindowDefined = typeof window !== 'undefined'
  const storedPassword = getCookieByName('ttf-access')

  if (storedPassword === ENV.TTF_ACCESS) {
    return
  }

  if (isWindowDefined && window.location.hostname === 'tickets.manacommon.com')
    return <AccessControl />
}
